<template>
  <div>
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('tasks.back_to_tasks') }}</v-btn>
    </info-modal>
    <base-dialog ref="deleteDialog" :title="$t('task.delete_message')" :text="$t('task.delete_text')" />
    <tasks-entity-header
      class="mb-10"
      :title="taskName"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :status="task.status"
      :for-me="isForMe"
      @delete-task="deleteTask(id)"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schemaRecurrence" :data="task" />
  </div>
</template>

<script>
// Models
import { schemaRecurrence } from '@/schemas/task.view.schema';
import { createModelData } from '@/schemas/createModelData';

// Services
import issuesService from '@/services/issues';
import analyticsService from '@/services/analytics';

// Constants
import { TASKS_RECURRENCE, TASKS_RECURRENCE_EDIT } from '@/constants/routes';
import { RULE_TASK } from '@/constants/analyticsActions';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Components
import TasksEntityHeader from '@/components/Tasks/EntityHeader.vue';
import InfoModal from '@/components/InfoModal.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';

export default {
  name: 'TaskDetailed',

  components: {
    TasksEntityHeader,
    ViewBuilder,
    InfoModal,
    BaseDialog,
  },
  inject: ['media'],

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      task: createModelData(schemaRecurrence),
      dataDeleteTask: {
        isShowModalDelete: false,
        taskName: '',
      },
      modal: {
        show: false,
        message: '',
        info: true,
      },
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, subjects.ISSUES);
    },

    isForMe() {
      return this.task.forMe;
    },

    backRoute() {
      return { name: TASKS_RECURRENCE, query: { page: this.prevPage || 1 } };
    },

    taskName() {
      if (this.isLoading) return '';
      return this.task.name;
    },

    editRoute() {
      return { name: TASKS_RECURRENCE_EDIT, params: { id: this.id } };
    },
  },

  async mounted() {
    analyticsService.track(RULE_TASK);
    this.isLoading = true;
    try {
      await this.initDetail();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async deleteTask(issueId) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.isLoading = true;
        try {
          await issuesService.deleteRecurrenceIssueById(issueId);
          this.showModal(this.$t('tasks.success_delete_task'));
          this.$router.push(this.backRoute);
        } catch (e) {
          this.showModal(this.$t('tasks.fail_delete_task'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    async initDetail() {
      const issue = await issuesService.getRecurrenceIssueById(this.id);

      this.task = {
        ...issue,
        serviceType: issue.serviceType,
        recurrencePeriod: this.$t(issue.recurrencePeriod.name),
        client: issue.client ? this.getFullName(issue.client) : null,
        project: this.getName(issue.project),
        building: this.getName(issue.building),
        unit: this.getName(issue.unit),
        room: issue.room ? this.getName(issue.room) : null,
        isPushInform: issue.isInformClientViaPush ? this.$t('yes') : this.$t('no'),
        pendingRoles: issue.roles?.pending,
        inProgressRoles: issue.roles?.inProgress,
        completedRoles: issue.roles?.completed,
        payments: {
          isPaymentRequired: issue.isPaymentRequired,
          paymentAmount: issue.isPaymentRequired ? issue.paymentAmount : null,
          paymentContractor: issue.isPaymentRequired ? issue.paymentContractor : null,
          paymentReceipt: issue.isPaymentRequired ? issue.paymentReceipt : null,
        },
        // infoCard: {
        //   info: {
        //     text: issue.isInformClientViaPush
        //       ? this.$t('tasks.inform_notification')
        //       : this.$t('tasks.un_inform_notification'),
        //     icon: issue.isInformClientViaPush ? 'mdi-bell-ring' : 'mdi-bell-off',
        //     colorIcon: issue.isInformClientViaPush ? 'blue' : '',
        //     show: true,
        //   },
        //   building: this.getName(issue.building),
        //   client: issue.client ? this.getFullName(issue.client) : null,
        //   project: this.getName(issue.project),
        //   unit: this.getName(issue.unit),
        // },
      };
    },
    getName(value) {
      return value.name;
    },
    getFullName(value) {
      return `${value.firstName} ${value.lastName}`;
    },
  },

  schemaRecurrence,
};
</script>
